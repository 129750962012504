<script setup lang="ts">
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';
import { FlightSearchParams, FlightSearchHistory } from 'types/flight';
import {
  ref,
  computed,
  useFlightSearchForm,
  useNavigation,
  watch,
  useFlightService,
  useAsyncData,
  toRef,
  useConfigStore,
  useTracker,
} from '#imports';

interface IProps {
  initialSearchParams?: FlightSearchHistory;
  showOrderListButton?: boolean;
  airlineCode?: string;
}

interface IEmits {
  (e: 'submit', data: FlightSearchHistory): void;
}

const props = withDefaults(defineProps<IProps>(), {
  showOrderListButton: true,
});

const emit = defineEmits<IEmits>();

const { navigate, navigationPath } = useNavigation();
const { jitsuEvent } = useTracker();
const config = useConfigStore();

const { data: fareData, execute: syncFares } = useAsyncData(
  () =>
    useFlightService().getFareCalendar({
      depAirport: depAirport.value!.code,
      arrAirport: arrAirport.value!.code,
      currency: config.currency!,
      isRoundTrip: searchType.value === 'roundtrip',
      tripType: cabinType.value,
    }),
  { immediate: false, watch: [toRef(config, 'currency')] },
);

const returnCalendarRef = ref<{ $el: HTMLElement }>();
const isShowingSuggestionOrigin = ref<boolean>(false);
const isShowingSuggestionDestination = ref<boolean>(false);
const rotateSwitchBtn = ref<boolean>(false);

const {
  searchTypes,
  searchType,
  cabinTypes,
  cabinType,
  depAirport,
  arrAirport,
  travelDates,
  travelers,
  errors,
  maxDate,
  switchAirport,
  handleSubmit,
} = useFlightSearchForm({ initialSearchParams: props.initialSearchParams });

const travelerCount = computed(() => travelers.value.adult + travelers.value.child + travelers.value.infant);

const faresData = computed(() => fareData.value?.result);

watch([depAirport, arrAirport, searchType, cabinType], ([depAirport, arrAirport]) => {
  if (!!depAirport?.code && !!arrAirport?.code) syncFares();
});

function onChangeSearchType(searchTypes: string) {
  const eventName =
    searchTypes === 'oneway' ? 'flight-searchform-routetype-oneway' : 'flight-searchform-routetype-roundtrip';

  jitsuEvent('user-switch-button', {
    event_name: eventName,
    product: 'flight',
    object_name: 'search-form',
    object_parameter: 'triptype',
    trip_type: searchTypes,
  });
}

function onClickTraveler() {
  jitsuEvent('user-click-button', {
    event_name: 'flight-searchform-traveler-expand',
    product: 'flight',
    object_name: 'search-form',
    object_parameter: 'traveler_expand',
  });
}

function onClickOrderList() {
  jitsuEvent('user-click-button', {
    event_name: 'flight-searchform-orderlist',
    product: 'flight',
    object_name: 'search-form',
  });
}

function onSubmit(data: FlightSearchParams) {
  emit('submit', { ...data, depAirportProperties: depAirport.value, arrAirportProperties: arrAirport.value });
  return navigate({ path: '/flight/search', query: data }, { externalAirpaz: true });
}
</script>

<template>
  <div class="bg-white rounded shadow-md p-20 min-w-[1100px]">
    <div class="flex justify-between mb-15 -mx-5">
      <div class="flex gap-x-10">
        <Listbox v-model="searchType">
          <div class="relative">
            <ListboxButton
              class="font-bold cursor-pointer p-5 rounded flex items-center gap-x-5 hover:bg-whiter"
              data-testid="flightSearch-type-textButton"
            >
              {{ $t('search.' + searchType) }}

              <ApzIcon
                :icon="['fas', 'chevron-down']"
                class="text-[12px]"
              />
            </ListboxButton>

            <ListboxOptions
              class="absolute bg-white rounded drop-shadow-lg mt-5 z-10 divide-gray-light divide-y-[0.5px] w-[210px] overflow-hidden"
            >
              <ListboxOption
                v-for="st in searchTypes"
                v-slot="{ active, selected }"
                :key="st"
                :value="st"
                as="template"
              >
                <div
                  class="relative text-base font-bold p-20 cursor-pointer"
                  data-testid="flightSearch-type-list"
                  :class="{ 'bg-whiter': active && !selected, 'text-primary': selected }"
                  @click="!selected && onChangeSearchType(st)"
                >
                  {{ $t('search.' + st) }}

                  <span
                    v-if="selected"
                    class="absolute right-15"
                  >
                    <ApzIcon icon="check" />
                  </span>
                </div>
              </ListboxOption>
            </ListboxOptions>
          </div>
        </Listbox>

        <Popover class="relative">
          <PopoverButton
            data-testid="flightSearch-traveler-textButton"
            class="text-base font-bold rounded p-5 flex items-center gap-x-5 hover:bg-whiter"
            @click="onClickTraveler"
          >
            <ApzIcon icon="user-friends" />

            {{ travelerCount }} {{ $t('global.passengers', travelerCount) }}

            <ApzIcon
              :icon="['fas', 'chevron-down']"
              class="text-[12px]"
            />
          </PopoverButton>

          <PopoverPanel
            v-slot="{ close }"
            class="absolute mt-5 bg-white rounded drop-shadow-lg w-[375px] z-10"
          >
            <LazyFlightTravelerPicker
              v-model:adult="travelers.adult"
              v-model:child="travelers.child"
              v-model:infant="travelers.infant"
              @done="close"
            />
          </PopoverPanel>
        </Popover>

        <Listbox v-model="cabinType">
          <div class="relative">
            <ListboxButton
              class="font-bold cursor-pointer p-5 rounded flex items-center gap-x-5 hover:bg-whiter"
              data-testid="flightSearch-type-textButton"
            >
              {{ $t(`flight.${cabinType}`) }}

              <ApzIcon
                :icon="['fas', 'chevron-down']"
                class="text-[12px]"
              />
            </ListboxButton>

            <ListboxOptions
              class="absolute bg-white rounded drop-shadow-lg mt-5 z-10 divide-gray-light divide-y-[0.5px] w-[210px] overflow-hidden"
            >
              <ListboxOption
                v-for="ct in cabinTypes"
                v-slot="{ active, selected }"
                :key="ct"
                :value="ct"
                as="template"
              >
                <div
                  class="relative text-base font-bold p-20 cursor-pointer"
                  data-testid="flightSearch-type-list"
                  :class="{ 'bg-whiter': active && !selected, 'text-primary': selected }"
                >
                  {{ $t(`flight.${ct}`) }}

                  <span
                    v-if="selected"
                    class="absolute right-15"
                  >
                    <ApzIcon icon="check" />
                  </span>
                </div>
              </ListboxOption>
            </ListboxOptions>
          </div>
        </Listbox>
      </div>

      <NuxtLink
        v-if="showOrderListButton"
        :to="navigationPath('/order')"
        external
        no-rel
        data-testid="flightSearch-order-textButton"
        class="text-base font-bold rounded hover:bg-whiter p-5 cursor-pointer"
        @click="onClickOrderList"
      >
        <span class="mr-5">
          <ApzIcon icon="edit" />
        </span>

        {{ $t('profile.orderlist') }}

        <span class="ml-5">
          <ApzIcon :icon="['far', 'angle-right']" />
        </span>
      </NuxtLink>
    </div>

    <div class="flex gap-x-10">
      <div class="relative flex gap-x-10">
        <ApzTooltip
          :shown="Boolean(errors.depAirport)"
          :triggers="[]"
          :hide-triggers="[]"
          no-auto-focus
          placement="top-start"
        >
          <FlightAirportPicker
            v-model="depAirport"
            :label="$t('search.from')"
            :title="isShowingSuggestionOrigin ? $t('flight.choosedestination') : $t('route.populardestination')"
            :placeholder="$t('search.placeholderfrom')"
            flight-type="origin"
            :class="[searchType === 'oneway' ? 'w-[370px] min-h-[57px]' : 'w-[247px] min-h-[57px]']"
            :airline-code="airlineCode"
            @keywords="
              (keyword) => {
                if (keyword.length > 0) {
                  isShowingSuggestionOrigin = true;

                  if (errors.depAirport) delete errors.depAirport;
                } else {
                  isShowingSuggestionOrigin = false;
                }
              }
            "
          />

          <template #popper>{{ errors.depAirport }}</template>
        </ApzTooltip>

        <ApzTooltip
          :shown="Boolean(errors.arrAirport)"
          :triggers="[]"
          :hide-triggers="[]"
          no-auto-focus
          placement="top-start"
        >
          <FlightAirportPicker
            v-model="arrAirport"
            :label="$t('global.to')"
            :title="isShowingSuggestionDestination ? $t('flight.choosedestination') : $t('route.populardestination')"
            :placeholder="$t('search.placeholderto')"
            flight-type="destination"
            :class="searchType === 'oneway' ? 'w-[340px] h-[57px]' : 'w-[266px] h-[57px]'"
            :dep-airport="depAirport?.code"
            :airline-code="airlineCode"
            @keywords="
              (keyword) => {
                if (keyword.length > 0) {
                  isShowingSuggestionDestination = true;

                  if (errors.arrAirport) delete errors.arrAirport;
                } else {
                  isShowingSuggestionDestination = false;
                }
              }
            "
          />

          <template #popper>{{ errors.arrAirport }}</template>
        </ApzTooltip>

        <ApzButton
          type="primary"
          data-testid="flightSearch-swapAirport-button"
          class="absolute top-1/2 -translate-x-1/2 -translate-y-1/2 w-30 !h-30 rounded-full transition-all delay-75 ease-linear"
          :class="[rotateSwitchBtn && 'transform rotate-180', searchType === 'oneway' ? 'left-[52%]' : 'left-[48%]']"
          @click="
            () => {
              rotateSwitchBtn = !rotateSwitchBtn;
              switchAirport();
            }
          "
        >
          <ApzIcon
            icon="repeat"
            class="text-[15px] rotate-180"
          />
        </ApzButton>
      </div>

      <div class="relative flex flex-1 gap-x-10">
        <Popover
          v-slot="{ open }"
          class="flex-1"
        >
          <ApzTooltip
            :shown="Boolean(errors.depDate)"
            :triggers="[]"
            :hide-triggers="[]"
            no-auto-focus
            placement="top-start"
          >
            <PopoverButton
              class="text-left w-full cursor-pointer rounded border-[0.5px] border-gray-light px-15 py-10 min-h-[57px] focus:outline-none"
              :class="{ 'border-primary box-border': open }"
            >
              <div class="text-small text-gray-dark">
                {{ $t('bookingform.departure') }}
              </div>

              <div
                class="text-base font-bold"
                data-testid="flightSearch-departure-datePicker"
              >
                {{ travelDates[0] ? $dt(travelDates[0], 'lllls') : $t('global.choosedate') }}
              </div>
            </PopoverButton>

            <template #popper>{{ errors.depDate }}</template>
          </ApzTooltip>

          <PopoverPanel
            v-slot="{ close }"
            class="absolute right-0 bg-white rounded drop-shadow-lg w-[720px] mt-10 z-10"
          >
            <SharedCalendarPicker
              id="flight-calendar"
              v-model="travelDates"
              :range="searchType === 'roundtrip'"
              :fares-data="faresData?.depart"
              :max-date="maxDate"
              @vue:mounted="
                jitsuEvent('user-click-field', {
                  event_name: 'flight-searchform-calendar-expand',
                  product: 'flight',
                  object_name: 'search-form',
                  object_parameter: 'calendar-expand',
                })
              "
              @done="
                () => {
                  jitsuEvent('user-click-preference', {
                    event_name: 'flight-searchform-calendar-departdate-select',
                    product: 'flight',
                    object_name: 'search-form',
                    object_parameter: 'calendar-depart',
                    has_price: faresData?.depart ? 1 : 0,
                    is_lowest_price: 0,
                  });
                  close();
                }
              "
              @pickend="searchType === 'roundtrip' && returnCalendarRef?.$el.click()"
            />
          </PopoverPanel>
        </Popover>

        <Popover
          v-if="searchType === 'roundtrip'"
          v-slot="{ open }"
          class="flex-1"
        >
          <ApzTooltip
            :shown="Boolean(errors.retDate)"
            :triggers="[]"
            :hide-triggers="[]"
            no-auto-focus
            placement="top-start"
          >
            <PopoverButton
              ref="returnCalendarRef"
              class="text-left cursor-pointer rounded border-[0.5px] border-gray-light px-15 py-10 w-[183px] h-[57px] focus:outline-none"
              :class="{ 'border-primary': open }"
            >
              <div class="text-small text-gray-dark">
                {{ $t('bookingform.return') }}
              </div>

              <div
                class="text-base font-bold"
                data-testid="flightSearch-return-datePicker"
              >
                {{ travelDates[1] ? $dt(travelDates[1], 'lllls') : $t('global.choosedate') }}
              </div>
            </PopoverButton>

            <template #popper>{{ errors.retDate }}</template>
          </ApzTooltip>

          <PopoverPanel
            v-slot="{ close }"
            class="absolute right-0 bg-white rounded drop-shadow-lg w-[720px] mt-10 z-10"
          >
            <SharedCalendarPicker
              id="flight-calendar"
              v-model="travelDates"
              :range="searchType === 'roundtrip'"
              pick-end
              :fares-data="faresData?.return"
              :max-date="maxDate"
              @vue:mounted="
                jitsuEvent('user-click-field', {
                  event_name: 'flight-searchform-calendar-expand',
                  product: 'flight',
                  object_name: 'search-form',
                  object_parameter: 'calendar-expand',
                })
              "
              @done="
                () => {
                  jitsuEvent('user-click-preference', {
                    event_name: 'flight-searchform-calendar-departdate-select',
                    product: 'flight',
                    object_name: 'search-form',
                    object_parameter: 'calendar-depart',
                    has_price: faresData?.depart ? 1 : 0,
                    // is_lowest_price: 0,
                  });

                  jitsuEvent('user-click-preference', {
                    event_name: 'flight-searchform-calendar-returndate-select',
                    product: 'flight',
                    object_name: 'search-form',
                    object_parameter: 'calendar-return',
                    has_price: faresData?.return ? 1 : 0,
                    // is_lowest_price: 0,
                  });
                  close();
                }
              "
            />
          </PopoverPanel>
        </Popover>
      </div>

      <ApzButton
        type="primary"
        :icon="['far', 'search']"
        class="w-[141px] h-auto"
        data-testid="flightSearch-searchFlight-button"
        @click="handleSubmit(onSubmit)"
      >
        {{ $t('global.search') }}
      </ApzButton>
    </div>
  </div>
</template>
